import { SellOutlined, SupportAgentOutlined } from "@mui/icons-material"
import {
  TableRow,
  TableCell,
  ListItemText,
  Avatar,
  Tooltip,
  Typography,
  IconButton,
  Badge,
  Button,
  Collapse,
  Paper,
  Stack,
} from "@mui/material"
import { Box } from "@mui/system"
import { formatDistance } from "date-fns"
import Iconify from "src/components/iconify"
import { CashApplication, Tag } from "src/types"
import { stringAvatar } from "src/utils/avatar"
import { fDate } from "src/utils/formatTime"
import { getStatusLabel } from "../../utils"
import { useNavigate } from "react-router-dom"
import { useUser } from "src/queries"
import { useState } from "react"
import { useBoolean } from "src/hooks/use-boolean"
import TagSelect from "../components/TagSelect"

export default ({
  row,
  index,
  handleOpenMenu,
}: {
  row: CashApplication
  index: number
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  const navigate = useNavigate()

  const { isCreditManager } = useUser()

  const collapse = useBoolean()

  const [tags, setTags] = useState<Array<Tag>>(row?.tags || [])

  const renderSecondary = (
    <TableRow>
      <TableCell sx={{ p: 0, border: "none" }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: "background.neutral" }}
        >
          <Stack component={Paper} sx={{ m: 1.5 }}>
            <TagSelect
              application={row}
              onTagsChanged={(newTags) => {
                setTags(newTags)
              }}
              sx={{
                p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                "&:not(:last-of-type)": {
                  borderBottom: (theme) =>
                    `solid 2px ${theme.palette.background.paper}`,
                },
              }}
            />
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  )

  return (
    <>
      <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
        <TableCell align="left">
          <ListItemText
            style={{
              cursor: isCreditManager ? "pointer" : "default",
            }}
            primaryTypographyProps={{
              typography: "subtitle2",
              sx: { textTransform: "capitalize" },
            }}
            secondaryTypographyProps={{
              typography: "caption",
            }}
            primary={
              <Box
                key={index}
                component="span"
                sx={{
                  color: "text.primary",
                }}
              >
                {row.data.legalBusinessName ||
                  `${row.data.firstName} ${row.data.lastName}`}
              </Box>
            }
            secondary={
              <Box
                key={index}
                component="span"
                sx={{
                  color: "text.secondary",
                }}
              >
                {row.data.email}
              </Box>
            }
            onClick={() => {
              navigate("/cash-applications/" + row.id + "/")
            }}
          />
        </TableCell>
        <TableCell align="left">{fDate(row.createdAt)}</TableCell>
        <TableCell align="left">{getStatusLabel(row.stage)}</TableCell>
        <TableCell align="left">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {row.assignee ? (
              <>
                <Avatar
                  {...stringAvatar(
                    `${
                      row.assignee.firstName
                        ? row.assignee.firstName
                        : row.assignee.email
                    }`,
                    {
                      width: 28,
                      height: 28,
                    },
                  )}
                />
                {row.assignee.firstName
                  ? `${row.assignee.firstName} ${
                      row.assignee.lastName ? row.assignee.lastName[0] : ""
                    }`
                  : row.assignee.email}
              </>
            ) : (
              <>Unassigned</>
            )}
          </Box>
        </TableCell>
        <TableCell align="left">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {row.salesRep ? (
              <>
                <SupportAgentOutlined />
                {row.salesRep.firstName
                  ? `${row.salesRep.firstName} ${
                      row.salesRep.lastName ? row.salesRep.lastName[0] : ""
                    }`
                  : row.salesRep.email}
              </>
            ) : (
              <>Unassigned</>
            )}
          </Box>
        </TableCell>

        <TableCell align="left">
          <Tooltip title={fDate(row.lastViewed)} placement="top" arrow>
            <Typography>
              {row.lastViewed
                ? formatDistance(new Date(row.lastViewed), new Date(), {
                    addSuffix: true,
                  })
                : ""}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell align="right">
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            {isCreditManager && tags.length ? (
              <Stack direction="row" alignItems="center">
                <Box sx={{ position: "relative" }}>
                  <SellOutlined />

                  <Badge
                    badgeContent={tags?.length}
                    overlap="circular"
                    color="secondary"
                    sx={{
                      top: -6,
                      px: 0.5,
                      left: 15,
                      height: 15,
                      position: "absolute",
                      borderBottomLeftRadius: 2,
                    }}
                  />
                </Box>

                <IconButton
                  color={collapse.value ? "inherit" : "default"}
                  onClick={collapse.onToggle}
                  sx={{
                    ...(collapse.value && {
                      bgcolor: "action.hover",
                    }),
                  }}
                >
                  <Iconify
                    icon={
                      collapse.value
                        ? "eva:arrow-ios-upward-fill"
                        : "eva:arrow-ios-downward-fill"
                    }
                  />
                </IconButton>
              </Stack>
            ) : (
              <Button color="inherit" onClick={collapse.onToggle}>
                + <SellOutlined color="inherit" />
              </Button>
            )}

            {row.stage !== 50 && (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              <IconButton
                id={`menu-${index}`}
                row={row.id}
                size="large"
                color="inherit"
                onClick={handleOpenMenu}
              >
                <Iconify icon={"eva:more-vertical-fill"} />
              </IconButton>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      {isCreditManager && renderSecondary}
    </>
  )
}
