import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  FormControl,
} from "@mui/material"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { ArrowForward } from "@mui/icons-material"
import ReactPhoneInput from "react-phone-input-material-ui"
import { useValidateEmail } from "src/queries/vendors/useValidateEmail"
import { useEffect, useState } from "react"
import FormLabel from "src/components/label/FormLabel"
import MobileNavButtonsBlock from "src/components/mobile-nav-buttons-block/MobileNavButtonsBlock"
import {
  PAGE_LABEL_TRADE_REFERENCES,
  TRADE_REFERENCE_CONFIGS,
} from "./constants"
import {
  isPageRequired,
  isSubSectionEnabled,
  isSubSectionRequired,
} from "./template_helpers"
import { CUSTOM_WORD_MAPPINGS, customWordMapping } from "src/statics"

const PAGE_LABEL = PAGE_LABEL_TRADE_REFERENCES

type Props = {
  size: number
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
  onSkip?: () => void
  template?: ApplicationTemplate
  handleBack: () => void
  activeStep: number
}
export default ({
  size,
  data,
  errors,
  onChange,
  onContinue,
  onSkip,
  template,
  handleBack,
  activeStep,
}: Props) => {
  const [showError, setShowError] = useState(false)
  const [emailToValidate, setEmailToValidate] = useState("")
  const { data: emailValidationResult } = useValidateEmail(
    emailToValidate ? data[emailToValidate] : "",
    "TRADEREFERENCE",
  )

  useEffect(() => {
    if (emailValidationResult?.verdict === "Invalid") {
      setShowError(true)
    }
  }, [emailValidationResult, emailToValidate])

  const requiredMinimum =
    Number(
      template?.formTemplate?.pages
        .find((p) => p.label === PAGE_LABEL_TRADE_REFERENCES)
        ?.config?.find((c) => c.label === "Minimum Required")?.value,
    ) || 1

  const matches = useMediaQuery("(max-width: 899px)")
  const matchesXL = useMediaQuery("(max-width: 1199px)")

  return (
    <>
      {!matchesXL && (
        <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
          Trade References
          {isPageRequired(PAGE_LABEL, template, data)
            ? ""
            : ` (${customWordMapping(
                CUSTOM_WORD_MAPPINGS.OPTIONAL,
                template,
              )})`}
        </Typography>
      )}
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        Your references should be vendors you have a credit account with. More
        information helps us offer you more suitable credit.
      </Typography>
      {isPageRequired(PAGE_LABEL, template, data) ? (
        <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
          At least{" "}
          <b>{requiredMinimum} Trade Reference(s) with an email address</b> is
          required to continue the application.{" "}
        </Typography>
      ) : (
        <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
          Trade references are optional for this application. However, if you
          decide to provide any, please make sure to include an email address.
        </Typography>
      )}
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        If you do not have any trade references please enter{" "}
        <b>noreply@netnow.com</b> in the email section in order to continue.
        Please use <b>N/A</b> or <b>0</b> in the other fields as needed.
      </Typography>
      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}

      {Array.from(Array(size).keys()).map((i) => (
        <Box key={i}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ marginTop: "8px", fontSize: "1.125rem" }}
          >
            Reference {i + 1}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <FormControl
              margin={matches ? "normal" : "none"}
              sx={{ width: "100%" }}
            >
              <FormLabel
                labelName="Business Name"
                idFor={"reference-name" + (i + 1)}
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  i < requiredMinimum
                }
              />
              <TextField
                id={"reference-name" + (i + 1)}
                margin={matches ? "none" : "normal"}
                fullWidth
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  i < requiredMinimum
                }
                label={matches ? "" : "Business Name"}
                placeholder={matches ? "Enter Name" : ""}
                value={data[`referenceBusinessName${i + 1}`]}
                onChange={(event) => {
                  onChange(
                    "referenceBusinessName" + (i + 1),
                    event.target.value,
                  )
                }}
                error={
                  !!errors && Boolean(errors[`referenceBusinessName${i + 1}`])
                }
              />
            </FormControl>
            <FormControl
              margin={matches ? "normal" : "none"}
              sx={{ width: "100%" }}
            >
              <FormLabel
                labelName="Account # (Optional) "
                idFor={"reference-account" + (i + 1)}
              />
              <TextField
                id={"reference-account" + (i + 1)}
                margin={matches ? "none" : "normal"}
                fullWidth
                label={matches ? "" : "Account # (Optional)"}
                placeholder={matches ? "Enter Account Number" : ""}
                value={data[`referenceAccountNumber${i + 1}`]}
                onChange={(event) => {
                  onChange(
                    "referenceAccountNumber" + (i + 1),
                    event.target.value,
                  )
                }}
              />
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <FormControl
              margin={matches ? "normal" : "none"}
              sx={{ width: "100%" }}
            >
              <FormLabel
                labelName="Credit/AR Email"
                idFor={"reference-email" + (i + 1)}
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  i < requiredMinimum
                }
              />
              <TextField
                id={"reference-email" + (i + 1)}
                margin={matches ? "none" : "normal"}
                fullWidth
                label={matches ? "" : "Credit/AR Email"}
                placeholder={matches ? "Enter Email" : ""}
                variant="outlined"
                value={data[`referenceEmail${i + 1}`]}
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  i < requiredMinimum
                }
                onChange={(event) => {
                  setEmailToValidate("")
                  setShowError(false)
                  onChange("referenceEmail" + (i + 1), event.target.value)
                }}
                onBlur={(event) => {
                  setEmailToValidate(`referenceEmail${i + 1}`)
                }}
                error={!!errors && Boolean(errors[`referenceEmail${i + 1}`])}
                helperText={
                  !!errors && `referenceEmail${i + 1}` in errors
                    ? errors[`referenceEmail${i + 1}`]
                    : ""
                }
              />
            </FormControl>
            <FormControl
              margin={matches ? "normal" : "none"}
              sx={{ width: "100%" }}
            >
              <FormLabel
                labelName="Credit/AR Phone Number"
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  i < requiredMinimum
                }
              />
              <ReactPhoneInput
                value={data[`referencePhoneNumber${i + 1}`] || "1"}
                country={"us"}
                onChange={(event) => {
                  if (event === "1") {
                    onChange("referencePhoneNumber" + (i + 1), undefined)
                  } else {
                    onChange("referencePhoneNumber" + (i + 1), "+" + event)
                  }
                }}
                countryCodeEditable={false}
                label={matches ? "" : "Credit/AR Phone Number"}
                component={TextField}
                containerStyle={{
                  width: "100%",
                  marginTop: matches ? "" : "16px",
                }}
                inputProps={{
                  id: "reference-phone-number" + (i + 1),
                  // style: { marginTop: "8px" },
                  required:
                    isPageRequired(PAGE_LABEL, template, data) &&
                    i < requiredMinimum,
                  error:
                    !!errors && Boolean(errors[`referencePhoneNumber${i + 1}`]),
                }}
              />
            </FormControl>
            {isSubSectionEnabled(
              template,
              PAGE_LABEL,
              TRADE_REFERENCE_CONFIGS.FAX_NUMBER,
            ) && (
              <FormControl
                margin={matches ? "normal" : "none"}
                sx={{ width: "100%" }}
              >
                <FormLabel
                  labelName="Credit/AR Fax Number"
                  required={
                    isPageRequired(PAGE_LABEL, template, data) &&
                    isSubSectionRequired(
                      template,
                      PAGE_LABEL,
                      TRADE_REFERENCE_CONFIGS.FAX_NUMBER,
                      data,
                    ) &&
                    i < requiredMinimum
                  }
                />
                <ReactPhoneInput
                  value={data[`referenceFaxNumber${i + 1}`] || "1"}
                  country={"us"}
                  onChange={(event) => {
                    if (event === "1") {
                      onChange("referenceFaxNumber" + (i + 1), undefined)
                    } else {
                      onChange("referenceFaxNumber" + (i + 1), "+" + event)
                    }
                  }}
                  countryCodeEditable={false}
                  label={matches ? "" : "Credit/AR Fax Number"}
                  component={TextField}
                  containerStyle={{
                    width: "100%",
                    marginTop: matches ? "" : "16px",
                  }}
                  inputProps={{
                    id: "reference-fax-number" + (i + 1),
                    // style: { marginTop: "8px" },
                    required:
                      isPageRequired(PAGE_LABEL, template, data) &&
                      isSubSectionRequired(
                        template,
                        PAGE_LABEL,
                        TRADE_REFERENCE_CONFIGS.FAX_NUMBER,
                        data,
                      ) &&
                      i < requiredMinimum,
                    error:
                      !!errors && Boolean(errors[`referenceFaxNumber${i + 1}`]),
                  }}
                />
              </FormControl>
            )}
          </Box>
        </Box>
      ))}
      {showError && (
        <Dialog open={showError}>
          <DialogTitle>Did you enter the right email?</DialogTitle>
          <DialogContent>
            <Typography>
              The email <b>{emailValidationResult?.email}</b> seems to not be
              correct. Please double check and make sure the address is valid to
              avoid delays in processing your application.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowError(false)
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {matches ? (
        <MobileNavButtonsBlock
          click={onContinue}
          disable={false}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "2rem",
          }}
        >
          <Button
            id="continue-trade-references"
            onClick={() => {
              onContinue()
            }}
            size="large"
            fullWidth
            endIcon={<ArrowForward />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Save & Continue
          </Button>
          {template?.formTemplate.pages.find(
            (step) => step.label === PAGE_LABEL,
          )?.skippable &&
            onSkip && (
              <Button
                id="continue-owner"
                onClick={() => {
                  if (onSkip) onSkip()
                }}
                size="large"
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
              >
                Skip
              </Button>
            )}
        </Box>
      )}
    </>
  )
}
