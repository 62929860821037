import { sr_RS_latin } from "@faker-js/faker"
import { DataKeysMap } from "src/types"

export const PAGE_LABEL_OWNERS = "Owners and Contacts"
export const PAGE_LABEL_PERSONAL_GUARANTY = "Personal Guaranty"
export const PAGE_LABEL_TRADE_REFERENCES = "Trade References"
export const PAGE_LABEL_BANK_REFERENCE = "Bank Reference"
export const PAGE_LABEL_USER_DETAILS = "User Details"
export const PAGE_LABEL_BUSINESS_DETAILS = "Business Details"
export const PAGE_LABEL_PROJECT_DETAILS = "Project Details"
export const PAGE_LABEL_ADDITIONAL_QUESTIONS = "Additional Questions"

export const BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR = "Credit Manager Selector"
export const BUSINESS_CONFIG_EIN = "EIN"
export const BUSINESS_CONFIG_REVENUE = "Revenue"
export const BUSINESS_CONFIG_STORE = "Store"
export const BUSINESS_CONFIG_DBA = "DBA"
export const BUSINESS_CONFIG_BUSINESS_ADDRESS = "Business Address"
export const BUSINESS_CONFIG_SHIPPING_ADDRESS = "Shipping Address"
export const BUSINESS_CONFIG_BILLING_ADDRESS = "Billing Address"
export const BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS =
  "Credit Card Billing Address"
export const BUSINESS_CONFIG_SERVICE_ADDRESS = "Service Address"

export const USER_CONFIG_INDIVIDUAL_CONSUMER_HOMEOWNER =
  "Individual/Consumer/Homeowner"
export const USER_CONFIG_APPLICANT_TYPE = "Applicant Type"
export const USER_CONFIG_ADD_ITEM_TO_APPLICANT_TYPE =
  "Add Item to Applicant Type"
export const USER_CONFIG_APPLICIANT_TYPE_LIST = "Applicant Type List"
export const USER_CONFIG_BUSINESS_TYPE = "Business Type"
export const USER_CONFIG_BUSINESS_TYPE_LIST = "Business Type List"

export const TRADE_REFERENCE_CONFIGS = {
  FAX_NUMBER: "Fax Number",
}

export const OWNER_CONFIGS = {
  OWNERS: "Owners",
  DRIVER_LICENSE: "Driver License",
  SSN_SIN: "SSN/SIN",
  OWNER_PHONE_NUMBER: "Owner Phone Number",
  OWNER_TITLE: "Owners Title",
  OWNER_PERCENTAGE: "Owners Percentage",
  DATE_OF_BIRTH: "Date of Birth",
  ADDRESS: "Address",
  AP_CONTACTS: "AP Contacts",
  NUMBER_OF_AP_CONTACTS: "Number of AP Contacts",
  AP_2_BASE: "AP 2",
  AP_2_NAME: "AP 2 Name",
  AP_2_EMAIL: "AP 2 Email",
  AP_2_PHONE: "AP 2 Phone",
  AP_2_ROLE: "AP 2 Role",
  CONTROLLER_BASE: "Controller",
  CONTROLLER_NAME: "Controller Name",
  CONTROLLER_EMAIL: "Controller Email",
  CONTROLLER_PHONE: "Controller Phone",
  CONTROLLER_ROLE: "Controller Role",
}

export const PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS =
  "Number of Guarantors"

export const BANK_REFERENCE_CONFIG_ROUTING_NUMBER = "Routing Number"

export const FORM_INPUT_NAMES = {
  // user details
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL: "email",
  USER_PHONE_NUMBER: "userPhoneNumber",
  USER_PHONE_NUMBER_EXT: "userPhoneNumberExt",
  APPLICANT_TYPE: "applicantType",
  BUSINESS_TYPE: "businessType",
  BUSINESS_TYPE_OTHER: "businessTypeOther",
  ROLE: "role",
  ROLE_OTHER: "roleOther",
  // business details
  LEGAL_BUSINESS_NAME: "legalBusinessName",
  BUSINESS_DBA: "businessDba",
  BUSINESS_COUNTRY: "businessCountry",
  SHIPPING_ADDRESS: "shippingAddress",
  SHIPPING_CITY: "shippingCity",
  SHIPPING_COUNTRY: "shippingCountry",
  SHIPPING_REGION: "shippingRegion",
  SHIPPING_POST_CODE: "shippingPostCode",
  EIN: "ein",
  REVENUE: "revenue",
  SALES_REP: "salesRep",
  ASSIGNEE: "assignee",
  STORE: "store",
  SALES_REP_NAME: "salesRepName",
}

// Keys from 0 to 100 are reserved for user details
// Avoid updating the keys because question conditions are dependent on them
export const USER_DETAILS_DATA_KEY: DataKeysMap = {
  // 0: FORM_INPUT_NAMES.APPLICANT_TYPE, added in each section thought the component
  1: FORM_INPUT_NAMES.BUSINESS_TYPE,
  2: FORM_INPUT_NAMES.ROLE,
}

// Keys from 100 to 200 are reserved for business details
// Avoid updating the keys because question conditions are dependent on them
export const BUSINESS_DETAILS_DATA_KEY: DataKeysMap = {
  100: FORM_INPUT_NAMES.REVENUE,
  110: FORM_INPUT_NAMES.BUSINESS_COUNTRY,
}

// Keys from 200 to 300 are reserved for owners and contacts details
// Avoid updating the keys because question conditions are dependent on them
export const OWNERS_DATA_KEY: DataKeysMap = {}

// Keys from 300 to 400 are reserved for trade references
// Avoid updating the keys because question conditions are dependent on them
export const TRADE_REFERENCE_DATA_KEY: DataKeysMap = {}

// Keys from 400 to 500 are reserved for bank references
// Avoid updating the keys because question conditions are dependent on them
export const BANK_REFERENCE_DATA_KEY: DataKeysMap = {}

// Keys from 500 to 600 are reserved for personal guaranty references
// Avoid updating the keys because question conditions are dependent on them
export const PERSONAL_GUARANTY_DATA_KEY: DataKeysMap = {}

// additional questions do not have data keys but needs to support on business type
export const ADDITIONAL_QUESTIONS_DATA_KEY: DataKeysMap = {}
