// @mui
import { Button, Tooltip } from "@mui/material"

import { useUser } from "src/queries"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"

import { useSnackbar } from "notistack"

import { LinkOutlined } from "@mui/icons-material"

export default ({ type = "credit" }: { type?: "credit" | "cash" }) => {
  const { data: userData } = useUser()
  const { enqueueSnackbar } = useSnackbar()

  const { data } = useApplicationTemplate(
    userData?.userToBusiness?.business?.id,
    false,
    userData?.userToBusiness?.business !== undefined,
  )

  if (!data || !userData) return <></>

  const path =
    type === "credit"
      ? "/ua-trade-credit/base?business_id="
      : "/ua-cod/base?business_id="

  let applicationUrl = ""
  if (location.host.includes("netnow.io")) {
    applicationUrl =
      userData?.userToBusiness?.business?.shortenedUrl !== undefined &&
      userData?.userToBusiness?.business?.shortenedUrl !== null
        ? "https://app.netnow.io/" +
          (userData?.userToBusiness?.business?.shortenedUrl as string)
        : "https://app.netnow.io/" +
          (userData?.userToBusiness?.business.name
            .replaceAll(" ", "")
            .toLowerCase() as string)

    if (type === "cash") {
      applicationUrl = applicationUrl + "-cod"
    }
  } else {
    applicationUrl =
      location.protocol + "//" + location.host + path + data.seller
  }

  if (!applicationUrl) return <></>

  return (
    <Tooltip title="Click to copy the public-facing applcation link for your organization to clipboard">
      <Button
        variant="outlined"
        sx={{ input: { cursor: "pointer" }, textTransform: "capitalize" }}
        startIcon={<LinkOutlined />}
        onClick={() => {
          enqueueSnackbar("Link copied to clipboard.", {
            variant: "info",
          })
          navigator.clipboard.writeText(applicationUrl)
        }}
      >
        Application Link
      </Button>
    </Tooltip>
  )
}
