import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  useMediaQuery,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material"

import { CreditApplication } from "src/types"
import { ArrowForward } from "@mui/icons-material"
import ReactPhoneInput from "react-phone-input-material-ui"
import Address from "../components/Address"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useLocation, useParams } from "react-router-dom"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import InputAdornment from "@mui/material/InputAdornment"

import FormLabel from "src/components/label/FormLabel"
import MobileNavButtonsBlock from "src/components/mobile-nav-buttons-block/MobileNavButtonsBlock"
import { isPageRequired } from "./template_helpers"
import { customWordMapping } from "src/statics"
import { NumericFormat } from "react-number-format"

const PAGE_LABEL = "Job Sheet"

type Props = {
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
  onSkip?: () => void
  onCustomChange: (key: string, value: any) => void
  setFilesInMemory: (files: Map<string, File[]>) => void
  filesInMemory: Map<string, File[]>
  handleBack: () => void
  activeStep: number
}
export default ({
  data,
  errors,
  onChange,
  onContinue,
  handleBack,
  activeStep,
}: Props) => {
  const params = useParams()
  const { id } = params
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id")

  const { data: application } = useCreditApplication(id || "")

  const { data: template, businessTemplate } = useApplicationTemplate(
    id ? application?.seller?.id : businessId || "",
    true,
    id ? !application?.seller?.id : !businessId,
    id,
    data,
  )

  const matches = useMediaQuery("(max-width: 899px)")
  const matchesXL = useMediaQuery("(max-width: 1199px)")

  return (
    <>
      {!matchesXL && (
        <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
          {customWordMapping("Job Sheet", businessTemplate)}
          {isPageRequired(PAGE_LABEL, template, data)
            ? ""
            : ` (${customWordMapping("Optional", businessTemplate)})`}
        </Typography>
      )}

      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}

      <Typography
        component="h1"
        variant="h5"
        style={{ marginTop: "8px" }}
        sx={{ fontSize: "1.125rem" }}
      >
        {customWordMapping("Job Details", businessTemplate)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <FormControl
          sx={{ width: "100%" }}
          margin={matches ? "normal" : "none"}
        >
          <FormLabel
            labelName="Job Name"
            idFor={"job-name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
          />
          <TextField
            id={"job-name"}
            margin={matches ? "none" : "normal"}
            fullWidth
            placeholder={matches ? "Enter Name" : ""}
            label={matches ? "" : "Job Name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
            inputProps={{
              style: { height: "56px" },
            }}
            value={data[`jobName`]}
            onChange={(event) => {
              onChange("jobName", event.target.value)
            }}
            error={!!errors && Boolean(errors[`jobName`])}
          />
        </FormControl>
      </Box>
      <FormControl sx={{ width: "100%" }} margin={matches ? "normal" : "none"}>
        <FormLabel
          labelName="Job Address"
          required={isPageRequired(PAGE_LABEL, template, data)}
        />

        <Address
          keyPrefix={"job"}
          data={data}
          onChange={() => {
            //TODO: Implement
          }}
          errors={errors}
          required={isPageRequired(PAGE_LABEL, template, data)}
          defaults={{ country: application?.seller?.country || "" }}
        />
      </FormControl>
      <FormControl sx={{ width: "100%" }} margin={matches ? "normal" : "none"}>
        <FormLabel labelName="job-phone-number" />
        <ReactPhoneInput
          value={data[`jobPhoneNumber`]}
          country={"us"}
          onChange={(event) => {
            onChange(`jobPhoneNumber`, "+" + event)
          }}
          countryCodeEditable={false}
          label={matches ? "" : "Job Phone Number"}
          component={TextField}
          containerStyle={{
            width: "100%",
            marginTop: "8px",
            height: "56px",
          }}
          inputProps={{
            id: `job-phone-number`,
            // required: isPageRequired(PAGE_LABEL, template),
            error: !!errors && Boolean(errors[`jobPhoneNumber`]),
            inputProps: {
              style: { height: "56px" },
            },
          }}
        />
      </FormControl>

      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}

      <Typography
        component="h1"
        variant="h5"
        style={{ marginTop: "8px" }}
        sx={{ fontSize: "1.125rem" }}
      >
        {customWordMapping("General Contractor Details", businessTemplate)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <FormControl
          sx={{ width: "100%" }}
          margin={matches ? "normal" : "none"}
        >
          <FormLabel
            labelName="General Contractor Name"
            idFor={"gc-name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
          />
          <TextField
            id={"gc-name"}
            margin={matches ? "none" : "normal"}
            fullWidth
            placeholder={matches ? "Enter Name" : ""}
            label={matches ? "" : "General Contractor Name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
            inputProps={{
              style: { height: "56px" },
            }}
            value={data[`gcName`]}
            onChange={(event) => {
              onChange("gcName", event.target.value)
            }}
            error={!!errors && Boolean(errors[`gcName`])}
          />
        </FormControl>
      </Box>
      <FormControl sx={{ width: "100%" }} margin={matches ? "normal" : "none"}>
        <FormLabel
          labelName="General Contractor Address"
          required={isPageRequired(PAGE_LABEL, template, data)}
        />

        <Address
          keyPrefix={"gc"}
          data={data}
          onChange={() => {
            //TODO: Implement
          }}
          errors={errors}
          required={isPageRequired(PAGE_LABEL, template, data)}
          defaults={{ country: application?.seller?.country || "" }}
        />
      </FormControl>
      <FormControl sx={{ width: "100%" }} margin={matches ? "normal" : "none"}>
        <FormLabel labelName="gc-phone-number" />
        <ReactPhoneInput
          value={data[`gcPhoneNumber`]}
          country={"us"}
          onChange={(event) => {
            onChange(`gcPhoneNumber`, "+" + event)
          }}
          countryCodeEditable={false}
          label={matches ? "" : "General Contractor Phone Number"}
          component={TextField}
          containerStyle={{
            width: "100%",
            marginTop: "8px",
            height: "56px",
          }}
          inputProps={{
            id: `gc-phone-number`,
            // required: isPageRequired(PAGE_LABEL, template),
            error: !!errors && Boolean(errors[`gcPhoneNumber`]),
            inputProps: {
              style: { height: "56px" },
            },
          }}
        />
      </FormControl>

      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}

      <Typography
        component="h1"
        variant="h5"
        style={{ marginTop: "8px" }}
        sx={{ fontSize: "1.125rem" }}
      >
        {customWordMapping("Construction Lender Details", businessTemplate)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <FormControl
          sx={{ width: "100%" }}
          margin={matches ? "normal" : "none"}
        >
          <FormLabel
            labelName="Construction Lender Name"
            idFor={"lender-name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
          />
          <TextField
            id={"lender-name"}
            margin={matches ? "none" : "normal"}
            fullWidth
            placeholder={matches ? "Enter Name" : ""}
            label={matches ? "" : "Construction Lender Name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
            inputProps={{
              style: { height: "56px" },
            }}
            value={data[`lenderName`]}
            onChange={(event) => {
              onChange("lenderName", event.target.value)
            }}
            error={!!errors && Boolean(errors[`lenderName`])}
          />
        </FormControl>
      </Box>
      <FormControl sx={{ width: "100%" }} margin={matches ? "normal" : "none"}>
        <FormLabel
          labelName="Construction Lender Address"
          required={isPageRequired(PAGE_LABEL, template, data)}
        />

        <Address
          keyPrefix={"lender"}
          data={data}
          onChange={() => {
            //TODO: Implement
          }}
          errors={errors}
          required={isPageRequired(PAGE_LABEL, template, data)}
          defaults={{ country: application?.seller?.country || "" }}
        />
      </FormControl>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <FormControl
          sx={{ width: "100%" }}
          margin={matches ? "normal" : "none"}
        >
          <FormLabel labelName="lender-phone-number" />
          <ReactPhoneInput
            value={data[`lenderPhoneNumber`]}
            country={"us"}
            onChange={(event) => {
              onChange(`lenderPhoneNumber`, "+" + event)
            }}
            countryCodeEditable={false}
            label={matches ? "" : "Construction Lender Phone Number"}
            component={TextField}
            containerStyle={{
              width: "100%",
              marginTop: "8px",
              height: "56px",
            }}
            inputProps={{
              id: `lender-phone-number`,
              // required: isPageRequired(PAGE_LABEL, template),
              error: !!errors && Boolean(errors[`lenderPhoneNumber`]),
              inputProps: {
                style: { height: "56px" },
              },
            }}
          />
        </FormControl>
        <FormControl
          fullWidth
          required={isPageRequired(PAGE_LABEL, template, data)}
        >
          <Box
            sx={
              !matches
                ? {
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "5%",
                    alignItems: "center",
                  }
                : {}
            }
          >
            <FormLabel
              labelName={"Construction Loan Amount"}
              required={isPageRequired(PAGE_LABEL, template, data)}
              showInDesktop={true}
              idFor={"construction-loan-amount"}
            />
            <NumericFormat
              fullWidth
              customInput={TextField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                error: Boolean(errors?.["constructionLoanAmount"]),
              }}
              value={data["constructionLoanAmount"]}
              onChange={(event) => {
                onChange("constructionLoanAmount", event.target.value)
              }}
              inputProps={{
                style: { maxHeight: "56px", height: "100%" },
              }}
              allowLeadingZeros
              thousandSeparator=","
            />
          </Box>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <FormControl
          fullWidth
          required={isPageRequired(PAGE_LABEL, template, data)}
        >
          <Box
            sx={
              !matches
                ? {
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "5%",
                    alignItems: "center",
                  }
                : {}
            }
          >
            <FormLabel
              labelName={"Date Material Required"}
              required={isPageRequired(PAGE_LABEL, template, data)}
              showInDesktop={true}
              idFor={"date-material-required"}
            />
            <TextField
              id={"date-material-required"}
              // margin="normal"
              fullWidth
              type="date"
              value={data["dateMaterialRequired"]}
              onChange={(event) => {
                onChange("dateMaterialRequired", event.target.value)
              }}
              inputProps={{
                style: { height: "56px" },
              }}
              error={Boolean(errors?.["dateMaterialRequired"])}
            />
          </Box>
        </FormControl>
        <FormControl
          fullWidth
          required={isPageRequired(PAGE_LABEL, template, data)}
        >
          <Box
            sx={
              !matches
                ? {
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "5%",
                    alignItems: "center",
                  }
                : {}
            }
          >
            <FormLabel
              labelName={"Project Start Date"}
              required={isPageRequired(PAGE_LABEL, template, data)}
              showInDesktop={true}
              idFor={"project-start-date"}
            />
            <TextField
              id={"project-start-date"}
              // margin="normal"
              fullWidth
              type="date"
              value={data["projectStartDate"]}
              onChange={(event) => {
                onChange("projectStartDate", event.target.value)
              }}
              inputProps={{
                style: { height: "56px" },
              }}
              error={Boolean(errors?.["projectStartDate"])}
            />
          </Box>
        </FormControl>
        <FormControl
          fullWidth
          required={isPageRequired(PAGE_LABEL, template, data)}
        >
          <Box
            sx={
              !matches
                ? {
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "5%",
                    alignItems: "center",
                  }
                : {}
            }
          >
            <FormLabel
              labelName={"Project End Date"}
              required={isPageRequired(PAGE_LABEL, template, data)}
              showInDesktop={true}
              idFor={"project-end-date"}
            />
            <TextField
              id={"project-end-date"}
              // margin="normal"
              fullWidth
              type="date"
              value={data["projectEndDate"]}
              onChange={(event) => {
                onChange("projectEndDate", event.target.value)
              }}
              inputProps={{
                style: { height: "56px" },
              }}
              error={Boolean(errors?.["projectEndDate"])}
            />
          </Box>
        </FormControl>
      </Box>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id="new-construction"
              onChange={(event) => {
                // onChange("projectEndDate", event.target.checked)
              }}
            />
          }
          label={`This is a new construction`}
        />
      </FormGroup>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id="reroofing"
              onChange={(event) => {
                //TODO: Implement
              }}
            />
          }
          label={`Includes reroofing`}
        />
      </FormGroup>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id="payment-performance-bonds"
              onChange={(event) => {
                //TODO: Implement
              }}
            />
          }
          label={`Payment and Performance Bonds Required`}
        />
      </FormGroup>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id="100pct-payment-performance-bonds"
              onChange={(event) => {
                //TODO: Implement
              }}
            />
          }
          label={`100% Payment and Performance Bonds`}
        />
      </FormGroup>

      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <FormControl
          sx={{ width: "100%" }}
          margin={matches ? "normal" : "none"}
        >
          <FormLabel
            labelName="Surety Name"
            idFor={"surety-name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
          />
          <TextField
            id={"surety-name"}
            margin={matches ? "none" : "normal"}
            fullWidth
            placeholder={matches ? "Enter Name" : ""}
            label={matches ? "" : "Surety Name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
            inputProps={{
              style: { height: "56px" },
            }}
            value={data[`suretyName`]}
            onChange={(event) => {
              onChange("suretyName", event.target.value)
            }}
            error={!!errors && Boolean(errors[`suretyName`])}
          />
        </FormControl>
        <FormControl
          sx={{ width: "100%" }}
          margin={matches ? "normal" : "none"}
        >
          <FormLabel
            labelName="Contact Name"
            idFor={"surety-contact-name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
          />
          <TextField
            id={"surety-contact-name"}
            margin={matches ? "none" : "normal"}
            fullWidth
            placeholder={matches ? "Enter Name" : ""}
            label={matches ? "" : "Surety Contact Name"}
            required={isPageRequired(PAGE_LABEL, template, data)}
            inputProps={{
              style: { height: "56px" },
            }}
            value={data[`suretyContactName`]}
            onChange={(event) => {
              onChange("suretyName", event.target.value)
            }}
            error={!!errors && Boolean(errors[`suretyContactName`])}
          />
        </FormControl>
      </Box>

      <FormControl sx={{ width: "100%" }} margin={matches ? "normal" : "none"}>
        <FormLabel
          labelName="Surety Address"
          required={isPageRequired(PAGE_LABEL, template, data)}
        />

        <Address
          keyPrefix={"surety"}
          data={data}
          onChange={() => {
            //TODO: Implement
          }}
          errors={errors}
          required={isPageRequired(PAGE_LABEL, template, data)}
          defaults={{ country: application?.seller?.country || "" }}
        />
      </FormControl>
      <FormControl sx={{ width: "100%" }} margin={matches ? "normal" : "none"}>
        <FormLabel labelName="surety-phone-number" />
        <ReactPhoneInput
          value={data[`suretyPhoneNumber`]}
          country={"us"}
          onChange={(event) => {
            onChange(`suretyPhoneNumber`, "+" + event)
          }}
          countryCodeEditable={false}
          label={matches ? "" : "Surety Phone Number"}
          component={TextField}
          containerStyle={{
            width: "100%",
            marginTop: "8px",
            height: "56px",
          }}
          inputProps={{
            id: `surety-phone-number`,
            // required: isPageRequired(PAGE_LABEL, template),
            error: !!errors && Boolean(errors[`suretyPhoneNumber`]),
            inputProps: {
              style: { height: "56px" },
            },
          }}
        />
      </FormControl>

      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <FormControl
          sx={{ width: "100%" }}
          margin={matches ? "normal" : "none"}
        >
          <FormLabel
            labelName="Bond No."
            idFor={"bond-no"}
            required={isPageRequired(PAGE_LABEL, template, data)}
          />
          <TextField
            id={"bond-no"}
            margin={matches ? "none" : "normal"}
            fullWidth
            placeholder={matches ? "Bond No." : ""}
            label={matches ? "" : "Bond No."}
            required={isPageRequired(PAGE_LABEL, template, data)}
            inputProps={{
              style: { height: "56px" },
            }}
            value={data[`bondNo`]}
            onChange={(event) => {
              onChange("bondNo", event.target.value)
            }}
            error={!!errors && Boolean(errors[`bondNo`])}
          />
        </FormControl>
        <FormControl
          fullWidth
          required={isPageRequired(PAGE_LABEL, template, data)}
        >
          <Box
            sx={
              !matches
                ? {
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "5%",
                    alignItems: "center",
                  }
                : {}
            }
          >
            <FormLabel
              labelName={"Bond Amount"}
              required={isPageRequired(PAGE_LABEL, template, data)}
              showInDesktop={true}
              idFor={"bond-amount"}
            />
            <NumericFormat
              fullWidth
              customInput={TextField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                error: Boolean(errors?.["bondAmount"]),
              }}
              value={data["bondAmount"]}
              onChange={(event) => {
                //TODO: Implement
              }}
              inputProps={{
                style: { maxHeight: "56px", height: "100%" },
              }}
              allowLeadingZeros
              thousandSeparator=","
            />
          </Box>
        </FormControl>
        <FormControl
          fullWidth
          required={isPageRequired(PAGE_LABEL, template, data)}
        >
          <Box
            sx={
              !matches
                ? {
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "5%",
                    alignItems: "center",
                  }
                : {}
            }
          >
            <FormLabel
              labelName={"Effective Date"}
              required={isPageRequired(PAGE_LABEL, template, data)}
              showInDesktop={true}
              idFor={"effective-date"}
            />
            <TextField
              id={"effective-date"}
              // margin="normal"
              fullWidth
              type="date"
              value={data["effectiveDate"]}
              onChange={(event) => {
                //TODO: Implement
              }}
              inputProps={{
                style: { height: "56px" },
              }}
              error={Boolean(errors?.["effectiveDate"])}
            />
          </Box>
        </FormControl>
      </Box>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id="manufacturer-warranty-required"
              onChange={(event) => {
                //TODO: Implement
              }}
            />
          }
          label={`Manufacturer Warrantry required`}
        />
      </FormGroup>

      <FormControl sx={{ width: "100%" }} margin={matches ? "normal" : "none"}>
        <FormLabel
          labelName="Material Manufacturer's Name"
          idFor={"manufacturer-name"}
          required={isPageRequired(PAGE_LABEL, template, data)}
        />
        <TextField
          id={"manufacturer-name"}
          margin={matches ? "none" : "normal"}
          fullWidth
          placeholder={matches ? "Material Manufacturer's Name" : ""}
          label={matches ? "" : "Material Manufacturer's Name"}
          required={isPageRequired(PAGE_LABEL, template, data)}
          inputProps={{
            style: { height: "56px" },
          }}
          value={data[`manufacturerName`]}
          onChange={(event) => {
            onChange("manufacturerName", event.target.value)
          }}
          error={!!errors && Boolean(errors[`manufacturerName`])}
        />
      </FormControl>

      {matches ? (
        <MobileNavButtonsBlock
          click={onContinue}
          disable={false}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "2rem",
          }}
        >
          <Button
            id="continue-owner"
            onClick={() => {
              onContinue()
            }}
            size="large"
            fullWidth
            endIcon={<ArrowForward />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Save & Continue
          </Button>
          {/* {template?.formTemplate.pages.find(
            (step) => step.label === PAGE_LABEL,
          )?.skippable &&
            onSkip && (
              <Button
                id="continue-owner"
                onClick={() => {
                  if (onSkip) onSkip()
                }}
                size="large"
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
              >
                Skip
              </Button>
            )} */}
        </Box>
      )}
    </>
  )
}
