import { Grid, Stack, TextField, Typography } from "@mui/material"
import { ApplicationTemplate } from "src/types"
import { useEffect, useState } from "react"
import { CUSTOM_WORD_MAPPINGS } from "src/statics"

export default function CustomWordMapping({
  applicationTemplate,
  onChange,
}: {
  applicationTemplate: ApplicationTemplate
  onChange: (value: { [key: string]: string }) => void
}) {
  const [customWordMapping, setCustomWordMapping] = useState(
    applicationTemplate.customWordMapping || {},
  )
  return (
    <Grid container spacing={2} sx={{ marginY: 4 }}>
      <Grid item xs={12}>
        <Typography variant="h5">Custom Word Mapping</Typography>
      </Grid>
      {Object.entries(CUSTOM_WORD_MAPPINGS).map(([key, word], index) => (
        <Grid item xs={12} key={index}>
          <Stack direction="row" spacing={2}>
            <Typography>{word}</Typography>
            <TextField
              value={customWordMapping[word] || ""}
              onChange={(e) => {
                if (e.target.value) {
                  customWordMapping[word] = e.target.value
                } else {
                  if (word in customWordMapping) {
                    delete customWordMapping[word]
                  }
                }
                setCustomWordMapping(customWordMapping)
                onChange(customWordMapping)
              }}
            />
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}
