import { useCallback } from "react"

import FormControl from "@mui/material/FormControl"

import CustomPopover, { usePopover } from "src/components/custom-popover"
import { fromUnixTime, getUnixTime, parseISO } from "date-fns"
import { format } from "date-fns"
import { IApplicationTableFilterValue } from "src/types"
import { Badge, Box, Button, TextField, Tooltip } from "@mui/material"
import { ArrowDropDown, DateRangeOutlined } from "@mui/icons-material"
import { useSnackbar } from "notistack"

// ----------------------------------------------------------------------

type Props = {
  values: number[]
  onFilters: (name: string, value: IApplicationTableFilterValue) => void
  //
}

export default function DateFilterMenu({ values, onFilters }: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const { open, onOpen, onClose } = usePopover()

  const handleFilterStartDate = useCallback(
    (newValue: number | null) => {
      onFilters("fromDate", newValue)
    },
    [onFilters],
  )

  const handleFilterEndDate = useCallback(
    (newValue: number | null) => {
      onFilters("toDate", newValue)
    },
    [onFilters],
  )

  return (
    <>
      <FormControl
        sx={{
          flexShrink: 0,
        }}
      >
        <Tooltip title="Filter by date range" placement="top">
          <Box
            style={{
              display: "flex",
              margin: "4px 8px 4px 0",
              gap: "4px",
              cursor: "pointer",
            }}
            onClick={onOpen}
          >
            {values[0] > 0 || values[1] > 0 ? (
              <Badge badgeContent={"!"} color="secondary">
                <DateRangeOutlined />
              </Badge>
            ) : (
              <DateRangeOutlined />
            )}
            <ArrowDropDown sx={{ cursor: "pointer", color: "#888888" }} />
          </Box>
        </Tooltip>
      </FormControl>
      <CustomPopover open={open} onClose={onClose} arrow="top-right">
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "16px",
            gap: "16px",
          }}
        >
          <TextField
            id={"list-filter-start-date"}
            focused
            type="date"
            label="From Date"
            sx={
              values[0] === 0
                ? {
                    "& label.Mui-focused": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                  }
                : {}
            }
            value={
              values[0] ? format(fromUnixTime(values[0]), "yyyy-MM-dd") : null
            }
            onKeyDown={(event) => {
              event.preventDefault()
            }}
            onChange={(event) => {
              const fromUnix = getUnixTime(parseISO(event.target.value))
              if (values[1] && values[1] > 0 && values[1] < fromUnix) {
                enqueueSnackbar("From date cannot be greater than to date", {
                  variant: "warning",
                })
                handleFilterEndDate(fromUnix)
              }
              handleFilterStartDate(fromUnix)
            }}
          />

          <TextField
            id={"list-filter-to-date"}
            focused
            type="date"
            label="To Date"
            sx={
              values[1] === 0
                ? {
                    "& label.Mui-focused": {
                      color: "secondary",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "secondary",
                      },
                    },
                  }
                : {}
            }
            value={
              values[1] ? format(fromUnixTime(values[1]), "yyyy-MM-dd") : null
            }
            onKeyDown={(event) => {
              event.preventDefault()
            }}
            onChange={(event) => {
              const toUnix = getUnixTime(parseISO(event.target.value))
              if (values[0] && values[0] > 0 && values[0] > toUnix) {
                enqueueSnackbar("From date cannot be greater than to date", {
                  variant: "warning",
                })
                handleFilterStartDate(toUnix)
              }
              handleFilterEndDate(toUnix)
            }}
          />
          <Button
            onClick={() => {
              handleFilterStartDate(null)
              handleFilterEndDate(null)
              onClose()
            }}
          >
            Clear
          </Button>
        </Box>
      </CustomPopover>
    </>
  )
}
