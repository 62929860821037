import {
  ArrowForward,
  ExpandMoreOutlined,
  Search,
  Lock,
  ThumbUp,
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  useTheme,
  Stack,
  Grid,
  useMediaQuery,
  FormControl,
} from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { usePlaidLink } from "react-plaid-link"
import { Application, ApplicationTemplate, CreditApplication } from "src/types"
import { usePlaidLinkToken } from "src/queries"
import ReactPhoneInput from "react-phone-input-material-ui"
import { PAGE_LABEL_BANK_REFERENCE } from "./constants"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import { isRoutingEnabled } from "./schemas/BankReferenceSectionSchema"
import { isPageRequired } from "./template_helpers"
import MobileNavButtonsBlock from "src/components/mobile-nav-buttons-block/MobileNavButtonsBlock"
import FormLabel from "src/components/label/FormLabel"

import * as Sentry from "@sentry/react"
import { CUSTOM_WORD_MAPPINGS, customWordMapping } from "src/statics"
import { hasCustomAddressQuestions } from "../utils"

const useStyles = makeStyles(() =>
  createStyles({
    MuiAccordionroot: {
      "&:before": {
        display: "none",
      },
    },
  }),
)

const PAGE_LABEL = PAGE_LABEL_BANK_REFERENCE

type Props = {
  application: Application
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCustomChange: (key: string, value: any) => void
  onContinue: () => void
  onSkip?: () => void
  template?: ApplicationTemplate
  setFilesInMemory: (files: Map<string, File[]>) => void
  filesInMemory: Map<string, File[]>
  handleBack: () => void
  activeStep: number
}

export default ({
  onContinue,
  onChange,
  onSkip,
  data,
  errors,
  template,
  onCustomChange,
  application,
  filesInMemory,
  setFilesInMemory,
  handleBack,
  activeStep,
}: Props) => {
  // experiment
  const [open, setOpen] = useState(true)
  const handleOpen = () => {
    setOpen((prev) => !prev)
  }
  const classes = useStyles()

  const theme = useTheme()

  const { data: business } = useAnonymousBusiness(data?.seller)

  const { enqueueSnackbar } = useSnackbar()

  const getBuyerAddress = () => {
    if (business?.country) {
      return business.country
    }
    if (data?.businessCountry) {
      return data.businessCountry
    }
    if (data?.shippingCountry) {
      return data?.shippingCountry
    }

    return "United States"
  }

  const { data: linkTokenResponse } = usePlaidLinkToken(true, getBuyerAddress())

  const {
    open: openPlaid,
    ready,
    error: plaidError,
  } = usePlaidLink({
    token: linkTokenResponse?.linkToken || "",
    onSuccess: (public_token) => {
      // this will be exchanged in the next step when application is submitted
      onChange("plaidPublicToken", public_token)
      onContinue()
    },
    onExit: (error, metadata) => {
      if (error) {
        Sentry.captureException(error)
        if (error.display_message) {
          enqueueSnackbar(
            "error: " +
              error.display_message +
              ". if fails again, please use the manual bank reference.",
            {
              variant: "error",
            },
          )
        } else {
          enqueueSnackbar(
            "An error occurred. if fails again, please use the manual bank reference.",
            {
              variant: "error",
            },
          )
        }
      }
    },
    onEvent: (eventName, metadata) => {
      if (eventName === "ERROR") {
        Sentry.captureException(metadata)
      }
    },
  })
  const getDefaultExpanded = () => {
    if (data?.plaidData) {
      return "panel1"
    }

    if (data?.bankName) {
      return "panel2"
    }
    return "panel1"
  }

  const [expanded, setExpanded] = React.useState<string | false>(
    getDefaultExpanded(),
  )

  useEffect(() => {
    if (plaidError) {
      Sentry.captureMessage(JSON.stringify(plaidError))
      if (plaidError.message) {
        enqueueSnackbar(
          "An error " +
            plaidError.message +
            "if fails again, please use the manual bank reference.",
          {
            variant: "error",
          },
        )
      } else {
        enqueueSnackbar(
          "An error occurred. if fails again, please use the manual bank reference.",
          {
            variant: "error",
          },
        )
      }
    }
  }, [plaidError, enqueueSnackbar])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  const matchesXL = useMediaQuery("(max-width: 1199px)")
  const matches = useMediaQuery("(max-width: 899px)")
  const matchesMobileM = useMediaQuery("(max-width: 375px)")

  return (
    <>
      {!matchesXL && (
        <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
          Bank Reference
          {isPageRequired(PAGE_LABEL, template, data)
            ? ""
            : ` (${customWordMapping(
                CUSTOM_WORD_MAPPINGS.OPTIONAL,
                template,
              )})`}
        </Typography>
      )}
      {matches ? (
        <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
          A bank reference can be provided in one of two ways. The Instant bank
          reference provides a secure, digital bank reference to us and helps us
          make a credit decision much faster. Manual bank reference can also be
          provided and will require you to provide your account number and
          contact information of your branch / account manager.
        </Typography>
      ) : (
        <Typography
          gutterBottom
          variant="body2"
          sx={{ color: "text.primary", textAlign: "left" }}
        >
          A bank reference can be provided in one of two ways. The{" "}
          <strong>Instant</strong> bank reference provides a secure, digital
          bank reference to us and helps us make a credit decision much faster.{" "}
          <strong>Manual</strong> bank reference can also be provided and will
          require you to provide your account number and contact information of
          your branch / account manager.
        </Typography>
      )}
      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{
          border: "solid",
          borderWidth: "1px",
          borderColor: matches ? "#00A76F" : theme.palette.success.dark,
          borderRadius: "16px",
          backgroundColor: "#F9FAFB",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
            }}
          >
            <Typography variant="h6">Instant Bank Reference</Typography>
            {matches ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: matchesMobileM ? "column" : "row",
                  justifyContent: matchesMobileM ? "start" : "space-between",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    padding: "5px",
                    borderRadius: "4px",
                    color: matches ? "#00A76F" : theme.palette.success.dark,
                    marginLeft: matches ? "0" : "4px",
                    backgroundColor: matches
                      ? "#00A76F1A"
                      : "rgb(34, 154, 22, 0.1)",
                  }}
                >
                  <strong>Most Secure Option</strong>
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    marginLeft: matchesMobileM ? "0" : "auto",
                    color: "text.secondary",
                    marginTop: "0.5em",
                  }}
                >
                  1 minute or less to complete
                </Typography>{" "}
              </Box>
            ) : (
              <>
                <Typography
                  variant="caption"
                  sx={{
                    padding: "5px",
                    borderRadius: "8px",
                    color: theme.palette.success.dark,
                    marginLeft: "16px",
                    backgroundColor: "rgb(34, 154, 22, 0.1)",
                  }}
                >
                  <strong>Recommended</strong>
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    marginLeft: "auto",
                    color: "text.secondary",
                    marginTop: "0.5em",
                  }}
                >
                  1 minute or less to complete
                </Typography>{" "}
              </>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!data.plaidData && (
            <>
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  p: 1,
                  backgroundColor: matches
                    ? "#00A76F1A"
                    : "rgb(34, 154, 22, 0.1)",
                  alignItems: "center",
                }}
              >
                <Typography variant="caption">
                  <Lock
                    sx={{
                      color: matches ? "#00A76F" : theme.palette.success.dark,
                      marginRight: "2px",
                    }}
                  />
                </Typography>
                <Typography variant="subtitle2" sx={{ marginTop: "0.26em" }}>
                  Your data is protected with 256-bit encryption
                </Typography>
              </Stack>
              <Grid sx={{ p: matches ? 0 : 1 }}>
                <Grid item>
                  <Typography variant="h6">How It works</Typography>
                </Grid>
                <Grid item>
                  <Stack
                    sx={{
                      p: matches ? 0 : 1,
                      alignItems: matches ? "" : "center",
                      flexDirection: matches ? "column" : "row",
                    }}
                  >
                    <Stack
                      sx={{
                        marginBottom: matches ? "5px" : "0",
                      }}
                    >
                      <Typography variant="body1" sx={{ color: "#000000" }}>
                        <strong style={{ fontWeight: "600" }}>Step 1</strong>
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: matches ? "center" : "start",
                        padding: matches ? "12px 10px" : "0",
                        backgroundColor: matches ? "#FFFFFF" : "inherit",
                        borderRadius: matches ? "10px" : "0",
                      }}
                    >
                      <Stack
                        sx={{
                          paddingX: matches ? 0 : 1,
                          margin: matches ? 0 : 1,
                          paddingRight: matches ? 1 : 0,
                        }}
                      >
                        <img
                          src="/assets/images/plaid-logo.png"
                          style={{
                            display: "block",
                            width: "40px",
                            height: "auto",
                            borderRadius: "4px",
                            maxWidth: "40px",
                          }}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant={matches ? "h4" : "h6"}>
                          We connect to your Bank via Plaid
                        </Typography>
                        <Typography
                          variant={matches ? "body2" : "caption"}
                          sx={{ color: "text.secondary", fontSize: "12px" }}
                        >
                          Plaid has partnered with 11,000+ financial
                          institutions across the US and Canada
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{
                      p: matches ? 0 : 1,
                      alignItems: matches ? "" : "center",
                      flexDirection: matches ? "column" : "row",
                    }}
                  >
                    <Stack sx={{ marginBottom: matches ? "5px" : "0" }}>
                      <Typography variant="body1" sx={{ color: "#000000" }}>
                        <strong style={{ fontWeight: "600" }}>Step 2</strong>
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: matches ? "center" : "start",
                        padding: matches ? "12px 10px" : "0",
                        backgroundColor: matches ? "#FFFFFF" : "inherit",
                        borderRadius: matches ? "10px" : "0",
                      }}
                    >
                      <Stack
                        sx={{
                          paddingX: matches ? 0 : 1,
                          margin: matches ? 0 : 1,
                          paddingRight: matches ? 1 : 0,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "rgb(34, 154, 22, 0.1)",
                            borderRadius: "4px",
                            width: "40px",
                            height: "40px",

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Lock
                            sx={{
                              color: theme.palette.success.dark,
                              width: "20px",
                              height: "auto",
                            }}
                          />
                        </Box>
                      </Stack>
                      <Stack>
                        <Typography variant={matches ? "h4" : "h6"}>
                          You login securely into your bank
                        </Typography>
                        <Typography
                          variant={matches ? "body2" : "caption"}
                          sx={{ color: "text.secondary", fontSize: "12px" }}
                        >
                          NetNow or the supplier will never get access to
                          credentials
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{
                      p: matches ? 0 : 1,
                      alignItems: matches ? "" : "center",
                      flexDirection: matches ? "column" : "row",
                    }}
                  >
                    <Stack sx={{ marginBottom: matches ? "5px" : "0" }}>
                      <Typography variant="body1" sx={{ color: "#000000" }}>
                        <strong style={{ fontWeight: "600" }}>Step 3</strong>
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: matches ? "center" : "start",
                        padding: matches ? "12px 10px" : "0",
                        backgroundColor: matches ? "#FFFFFF" : "inherit",
                        borderRadius: matches ? "10px" : "0",
                      }}
                    >
                      <Stack
                        sx={{
                          paddingX: matches ? 0 : 1,
                          margin: matches ? 0 : 1,
                          paddingRight: matches ? 1 : 0,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#B95CF4",
                            borderRadius: "4px",

                            width: "40px",
                            height: "40px",

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ThumbUp
                            fontSize="small"
                            sx={{
                              color: theme.palette.info.contrastText,
                            }}
                          />
                        </Box>
                      </Stack>
                      <Stack>
                        <Typography variant={matches ? "h4" : "h6"}>
                          You give consent for Bank Reference Authorization
                        </Typography>
                        <Typography
                          variant={matches ? "body2" : "caption"}
                          sx={{ color: "text.secondary", fontSize: "12px" }}
                        >
                          You share limited READ-ONLY information like account
                          balance and transactions
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ paddingX: 1 }}
                  >
                    <Stack sx={{ marginTop: 3 }}>
                      <Stack>
                        <Typography variant="caption">
                          <strong>
                            Plaid is trusted by 1000+ organizations Including:
                          </strong>
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={{ marginTop: 1 }}>
                        <Stack sx={{ paddingX: 1.1 }}>
                          <img
                            src="/assets/images/Venmo.png"
                            style={{
                              width: "80px",
                              opacity: 0.2,
                            }}
                          />
                        </Stack>
                        <Stack sx={{ paddingX: 1 }}>
                          <img
                            src="/assets/images/Betterment.png"
                            style={{ width: "140px", opacity: 0.2 }}
                          />
                        </Stack>
                        <Stack sx={{ paddingX: 1 }}>
                          <img
                            src="/assets/images/Dave.png"
                            style={{ width: "70px", opacity: 0.2 }}
                          />
                        </Stack>
                        <Stack sx={{ paddingX: 1, marginTop: -0.5 }}>
                          <img
                            src="/assets/images/RBC.png"
                            style={{ width: "20px", opacity: 0.2 }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack>
                      {!matches && (
                        <LoadingButton
                          loading={!ready}
                          endIcon={<ArrowForward />}
                          size="large"
                          onClick={() => {
                            openPlaid()
                          }}
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Find my bank
                        </LoadingButton>
                      )}
                    </Stack>
                  </Stack>
                </Grid>
                <Box>
                  {matches && (
                    <MobileNavButtonsBlock
                      click={() => {
                        openPlaid()
                      }}
                      loading={!ready}
                      disable={false}
                      handleBack={handleBack}
                      activeStep={activeStep}
                      continueText="Find My Bank"
                      arrow={<ArrowForward />}
                    />
                  )}
                </Box>
                <Grid item>
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{ color: "text.secondary" }}
                  >
                    <b>Warning</b>: Please disconnect from VPNs to use{" "}
                    <b>Instant Bank Reference</b>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {data.plaidData &&
            data?.plaidData?.bankInfo?.accounts?.map((a: any) => (
              <Stack key={a.account_id}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "center",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <Typography>{a.name}</Typography>
                  <Typography>{a.officialName}</Typography>
                </Box>
                <Typography>**********{a.mask}</Typography>
                <LoadingButton
                  onClick={() => {
                    onContinue()
                  }}
                  size="large"
                  fullWidth
                  endIcon={<ArrowForward />}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Continue
                </LoadingButton>
                <LoadingButton
                  loading={!ready}
                  startIcon={<Search />}
                  size="large"
                  onClick={() => {
                    onChange("plaidData", undefined)
                    openPlaid()
                  }}
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Connect another account
                </LoadingButton>
              </Stack>
            ))}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        style={{
          border: "solid",
          borderWidth: "1px",
          borderColor: "#D6E4FF",
          borderRadius: "16px",
          marginTop: "16px",
        }}
        classes={{
          root: classes.MuiAccordionroot,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Box
            sx={{ display: "flex", flexDirection: matches ? "column" : "row" }}
          >
            <Typography variant="h6" sx={{ width: "33%", flexShrink: 0 }}>
              Manual
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "14px" }}>
              Provide details of your bank account and representative. This
              typically results in a longer approval process and might result in
              a lower credit limit
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="h5"
            style={{ margin: "8px 0", fontSize: "12px" }}
          >
            We will not use this information for any payment processing.
          </Typography>
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <FormLabel
              labelName="Bank Name"
              required={isPageRequired(PAGE_LABEL, template, data)}
              idFor="bank-name"
            />
            <TextField
              id="bank-name"
              margin={matches ? "none" : "normal"}
              fullWidth
              label={matches ? "" : "Bank Name"}
              placeholder={matches ? "Enter Name" : ""}
              value={data.bankName}
              onChange={(event) => {
                onChange("bankName", event.target.value)
              }}
              required={isPageRequired(PAGE_LABEL, template, data)}
              error={Boolean(errors?.bankName)}
              inputProps={
                hasCustomAddressQuestions(
                  template?.customFields,
                  PAGE_LABEL,
                  data,
                )
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
            />
          </FormControl>

          {data.businessCountry === "Canada" && (
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: matches ? "column" : "row",
                gap: matches ? "0" : "32px",
              }}
            >
              <FormControl
                sx={{ width: "100%" }}
                margin={matches ? "normal" : "none"}
              >
                <FormLabel
                  labelName="Account Number"
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  idFor="account-number"
                />
                <TextField
                  id="account-number"
                  margin={matches ? "none" : "normal"}
                  fullWidth
                  label={matches ? "" : "Account Number"}
                  placeholder={matches ? "Enter Account Number" : ""}
                  value={data.bankAccountNumber}
                  onChange={(event) => {
                    onChange("bankAccountNumber", event.target.value.trim())
                  }}
                  error={Boolean(errors?.bankAccountNumber)}
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  helperText={errors?.bankAccountNumber}
                  inputProps={
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                />
              </FormControl>
              <FormControl
                sx={{ width: "100%" }}
                margin={matches ? "normal" : "none"}
              >
                <FormLabel
                  labelName="Transit Number"
                  idFor="transit-number"
                  required={isPageRequired(PAGE_LABEL, template, data)}
                />

                <TextField
                  id="transit-number"
                  margin={matches ? "none" : "normal"}
                  fullWidth
                  label={matches ? "" : "Transit Number"}
                  placeholder={matches ? "Enter Transit Number" : ""}
                  value={data.bankTransitNumber}
                  onChange={(event) => {
                    onChange("bankTransitNumber", event.target.value.trim())
                  }}
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  error={Boolean(errors?.bankTransitNumber)}
                  helperText={errors?.bankTransitNumber}
                  inputProps={
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                />
              </FormControl>
              <FormControl
                sx={{ width: "100%" }}
                margin={matches ? "normal" : "none"}
              >
                <FormLabel
                  labelName="Institution Number"
                  idFor="institution-number"
                  required={isPageRequired(PAGE_LABEL, template, data)}
                />
                <TextField
                  id="institution-number"
                  margin={matches ? "none" : "normal"}
                  fullWidth
                  label={matches ? "" : "Institution Number"}
                  placeholder={matches ? "Enter Institution Number" : ""}
                  value={data.bankInstitutionNumber}
                  onChange={(event) => {
                    onChange("bankInstitutionNumber", event.target.value.trim())
                  }}
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  error={Boolean(errors?.bankInstitutionNumber)}
                  helperText={errors?.bankInstitutionNumber}
                  inputProps={
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                />
              </FormControl>
            </Box>
          )}
          {data.businessCountry === "United States" && (
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: matches ? "column" : "row",
                gap: matches ? "0" : "32px",
              }}
            >
              <FormControl
                sx={{ width: "100%" }}
                margin={matches ? "normal" : "none"}
              >
                <FormLabel
                  labelName="Account Number"
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  idFor="account-number-2"
                />

                <TextField
                  id="account-number"
                  margin={matches ? "none" : "normal"}
                  fullWidth
                  label={matches ? "" : "Account Number"}
                  placeholder={matches ? "Enter Account Number" : ""}
                  value={data.bankAccountNumber}
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  onChange={(event) => {
                    onChange("bankAccountNumber", event.target.value.trim())
                  }}
                  error={Boolean(errors?.bankAccountNumber)}
                  helperText={errors?.bankAccountNumber}
                  inputProps={
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                />
              </FormControl>
              {isRoutingEnabled(template, data) && (
                <FormControl
                  sx={{ width: "100%" }}
                  margin={matches ? "normal" : "none"}
                >
                  <FormLabel
                    labelName="Routing Number"
                    idFor="routing-number"
                    required={isPageRequired(PAGE_LABEL, template, data)}
                  />
                  <TextField
                    id="routing-number"
                    margin={matches ? "none" : "normal"}
                    fullWidth
                    label={matches ? "" : "Routing Number"}
                    placeholder={matches ? "Enter Routing Number" : ""}
                    value={data.bankRoutingNumber}
                    onChange={(event) => {
                      onChange("bankRoutingNumber", event.target.value.trim())
                    }}
                    required={isPageRequired(PAGE_LABEL, template, data)}
                    error={Boolean(errors?.bankRoutingNumber)}
                    helperText={errors?.bankRoutingNumber}
                    inputProps={
                      hasCustomAddressQuestions(
                        template?.customFields,
                        PAGE_LABEL,
                        data,
                      )
                        ? {
                            style: { height: "56px" },
                          }
                        : {}
                    }
                  />
                </FormControl>
              )}
            </Box>
          )}
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: matches ? "column" : "row",
              gap: matches ? "0" : "32px",
            }}
          >
            <FormControl
              sx={{ width: "100%" }}
              margin={matches ? "normal" : "none"}
            >
              <FormLabel
                labelName="Account Manager Name"
                idFor="account-manager-name"
                required={isPageRequired(PAGE_LABEL, template, data)}
              />
              <TextField
                id="account-manager-name"
                margin={matches ? "none" : "normal"}
                fullWidth
                label={matches ? "" : "Account Manager Name"}
                placeholder={matches ? "Enter Name" : ""}
                value={data.bankContactName}
                onChange={(event) => {
                  onChange("bankContactName", event.target.value)
                }}
                required={isPageRequired(PAGE_LABEL, template, data)}
                error={Boolean(errors?.bankContactName)}
                inputProps={
                  hasCustomAddressQuestions(
                    template?.customFields,
                    PAGE_LABEL,
                    data,
                  )
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
              />
            </FormControl>
            <FormControl
              sx={{ width: "100%" }}
              margin={matches ? "normal" : "none"}
            >
              <FormLabel
                labelName="Account Manager Email"
                required={isPageRequired(PAGE_LABEL, template, data)}
                idFor="account-manager-email"
              />
              <TextField
                id="account-manager-email"
                margin={matches ? "none" : "normal"}
                fullWidth
                label={matches ? "" : "Account Manager Email"}
                placeholder={matches ? "Enter Email" : ""}
                value={data.bankContactEmail}
                onChange={(event) => {
                  onChange("bankContactEmail", event.target.value)
                }}
                required={isPageRequired(PAGE_LABEL, template, data)}
                error={Boolean(errors?.bankContactEmail)}
                inputProps={
                  hasCustomAddressQuestions(
                    template?.customFields,
                    PAGE_LABEL,
                    data,
                  )
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
              />
            </FormControl>
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <TextField
              margin="normal"
              fullWidth
              label="Line of Credit Amount (if applicable)"
              value={data.bankContactName}
              onChange={(event) => {
                onChange("bankContactName", event.target.value)
              }}
              error={Boolean(errors?.bankContactName)}
            />
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              <FormControl
                sx={{ width: "100%" }}
                margin={matches ? "normal" : "none"}
              >
                <FormLabel
                  labelName="Account Manager Phone Number"
                  required={isPageRequired(PAGE_LABEL, template, data)}
                />
                <ReactPhoneInput
                  value={data.bankContactPhoneNumber}
                  country={"us"}
                  onChange={(event) => {
                    onChange("bankContactPhoneNumber", "+" + event)
                  }}
                  countryCodeEditable={false}
                  label={matches ? "" : "Account Manager Phone Number"}
                  component={TextField}
                  containerStyle={{ width: "100%" }}
                  inputProps={{
                    required: isPageRequired(PAGE_LABEL, template, data),
                    error: Boolean(errors?.bankContactPhoneNumber),
                    margin: matches ? "none" : "normal",
                  }}
                />
              </FormControl>
            </Box>
          </Box>

          {template?.customFields && (
            <ReusableCustomQuestions
              application={application}
              associatedPage={PAGE_LABEL}
              dataKeyResponses={data}
              data={data["customFields"]}
              errors={errors}
              onChange={onCustomChange}
              filesInMemory={filesInMemory}
              setFilesInMemory={setFilesInMemory}
            />
          )}
          {template?.formTemplate.pages.find(
            (step) => step.label === PAGE_LABEL,
          )?.skippable &&
            onSkip && (
              <Button
                id="continue-owner"
                onClick={() => {
                  if (onSkip) onSkip()
                }}
                size="large"
                variant="outlined"
                sx={
                  !matches ? { mt: 3, mb: 2, width: "33%" } : { mt: 3, mb: 2 }
                }
              >
                Skip
              </Button>
            )}

          <MobileNavButtonsBlock
            click={() => {
              onContinue()
            }}
            disable={false}
            handleBack={handleBack}
            activeStep={activeStep}
            continueText="Submit Manual Reference"
            arrow={<ArrowForward />}
            isLongName={true}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
