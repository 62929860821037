import { useQuery } from "react-query"
import { ApplicationCount } from "src/types"
import queryString from "query-string"
export function useApplicationCount(filters?: Record<string, any>) {
  return useQuery<ApplicationCount>(
    "/application/count?" + queryString.stringify(filters || {}),
    {
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
