import { useCallback, useState } from "react"

import MenuItem from "@mui/material/MenuItem"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"

import { IApplicationTableFilterValue, Store } from "src/types"
import { Badge, InputBase, Tooltip } from "@mui/material"
import { StoreOutlined } from "@mui/icons-material"

// ----------------------------------------------------------------------

type Props = {
  values: Store[]
  onFilters: (name: string, value: IApplicationTableFilterValue) => void
  //
  options: Store[]
  field: string
}

export default function StoreFilterMenu({
  field,
  values,
  onFilters,
  //
  options,
}: Props) {
  const handleFilterRole = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        field,
        options.filter((option) => event.target.value.includes(option.id)),
      )
    },
    [field, onFilters, options],
  )
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <FormControl
        sx={{
          flexShrink: 0,
        }}
      >
        <Tooltip title="Filter by store/location" placement="top">
          <Select
            autoWidth
            multiple
            value={values.map((v) => v.id)}
            onChange={handleFilterRole}
            open={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
            input={
              <InputBase
                inputProps={{
                  disableUnderline: true,
                }}
                startAdornment={
                  values.length > 0 ? (
                    <Badge
                      badgeContent={values.length}
                      color="secondary"
                      sx={{ cursor: "pointer" }}
                    >
                      <StoreOutlined
                        onClick={() => {
                          setMenuOpen(true)
                        }}
                      />
                    </Badge>
                  ) : (
                    <StoreOutlined
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setMenuOpen(true)
                      }}
                    />
                  )
                }
              />
            }
            renderValue={() => <></>}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <Checkbox
                  disableRipple
                  checked={!!values.find((v) => v.id === option.id)}
                />
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>
    </>
  )
}
