import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { Outlet } from "react-router-dom"
import { useLocation, useParams } from "react-router-dom"
import { useAnonymousBusiness } from "../../queries/credit/useAnonymousBusiness"
import { useEffect } from "react"
import { loadLogoIntoImg } from "../../utils/imageTools"
import { DynamicThemeProvider } from "../../theme"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { LogoutOutlined } from "@mui/icons-material"
import { useAuth0 } from "@auth0/auth0-react"
import { useCashApplication } from "src/queries/cod/useCashApplication"
import { getDisplayContent } from "src/utils/form-content"
import * as Sentry from "@sentry/react"

// ----------------------------------------------------------------------

export default function () {
  const { search, pathname } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""

  const { data } = useAnonymousBusiness(businessId)
  const { id } = useParams()
  const { data: application } = useCreditApplication(
    pathname.includes("cod") ? undefined : id,
  )

  const { data: cashApplication } = useCashApplication(
    pathname.includes("cod") ? id : undefined,
  )
  const displayContent = getDisplayContent(
    queryParams.get("request_type") || "",
  )

  const logoOverride = queryParams.get("logo")

  const { logout } = useAuth0()

  const logo =
    logoOverride ||
    data?.logo ||
    application?.seller?.logo ||
    cashApplication?.seller?.logo

  const matches = useMediaQuery("(max-width: 1199px)")

  useEffect(() => {
    loadLogoIntoImg(document.getElementById("company-logo-desktop"), logo)
  }, [logo])

  const domLanguageSelectorItems = document.querySelector<HTMLElement>(
    "#weglot-listbox",
  )?.children as HTMLCollectionOf<HTMLElement>

  useEffect(() => {
    try {
      if (data?.supportedLanguages && domLanguageSelectorItems) {
        if (domLanguageSelectorItems) {
          // iterate through Weglot language selector item
          for (let i = 0; i < domLanguageSelectorItems?.length; i++) {
            if (domLanguageSelectorItems[i]["id"].includes("wg-")) {
              const lang = domLanguageSelectorItems[i]["id"].split("-")[1]
              // hide those that are not supported
              if (!data?.supportedLanguages.includes(lang)) {
                domLanguageSelectorItems[i].style.display = "none"
              }
            }
          }
        }
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [data?.supportedLanguages, domLanguageSelectorItems])

  return (
    <DynamicThemeProvider>
      {!data && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <CircularProgress color="info" />
        </Box>
      )}
      {data && (
        <>
          <Grid container component="main" sx={{ height: "100vh" }}>
            {!matches && (
              <Grid
                item
                lg={4}
                xl={4}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  width: "100%",
                }}
                sx={{ height: "auto" }}
              >
                <Box
                  display={{ xs: "none", lg: "block", xl: "block" }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10%",
                    alignContent: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      padding: "10% 25% 20% 25%",
                      justifyContent: "center",
                    }}
                  >
                    <img id="company-logo-desktop" />
                  </Box>
                  <Typography variant="h4">{displayContent}</Typography>
                </Box>

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    bottom: "5%",
                    position: "absolute",
                  }}
                >
                  <Box
                    display={{ xs: "none", lg: "flex", xl: "flex" }}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img src="/assets/soc2.png" style={{ width: "72px" }} />
                    <img src="/assets/ssl.png" style={{ height: "72px" }} />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      powered by
                    </Typography>
                    <img
                      src="/assets/logo-simple.png"
                      style={{ width: "32px" }}
                    />
                    <Typography sx={{ fontWeight: "bold" }}>NetNow</Typography>
                    <Button
                      variant="outlined"
                      startIcon={<LogoutOutlined />}
                      style={{ marginLeft: "5rem" }}
                      onClick={() => {
                        logout()
                      }}
                    >
                      Logout
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              component={Paper}
              style={{
                backgroundColor: "#F9FAFB",
              }}
              elevation={4}
              square
            >
              <Outlet />
            </Grid>
          </Grid>
        </>
      )}
    </DynamicThemeProvider>
  )
}
