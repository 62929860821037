import {
  Box,
  Button,
  FormGroup,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useInvoice } from "../../../queries/invoices/useInvoice"
import { useState } from "react"
import { formatCurrency } from "../../../utils/formatNumber"
import { styled } from "@mui/material/styles"

type Props = {
  id: string
  toggle: (arg0: boolean) => void
}

const DisabledTextField = styled(TextField)<TextFieldProps>(() => ({
  "& .Mui-disabled": {
    color: "rgba(0,0,0,1)",
    "-webkit-text-fill-color": "black !important",
  },
}))

export default function InvoiceViewModal({ id, toggle }: Props) {
  const { data } = useInvoice(id)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  if (!data) {
    return <></>
  }

  return (
    <Dialog fullWidth open={id !== undefined} onClose={() => toggle(false)}>
      <DialogTitle>View Invoice</DialogTitle>
      <DialogContent>
        <FormGroup style={{ gap: "10px" }}>
          <DisabledTextField
            disabled
            margin="dense"
            id="number"
            label="Invoice number"
            type="text"
            variant="standard"
            fullWidth
            value={data.number}
          />
          <DisabledTextField
            disabled
            margin="dense"
            id="netTerms"
            label="Net terms (days)"
            type="text"
            variant="standard"
            fullWidth
            value={data.netTerms}
          />
          <DisabledTextField
            disabled
            margin="dense"
            id="dueDate"
            label="Due date"
            type="text"
            variant="standard"
            fullWidth
            value={data.dueDate}
          />
          <DisabledTextField
            disabled
            margin="dense"
            id="number"
            label="Total due"
            type="text"
            fullWidth
            variant="standard"
            value={formatCurrency(data.totalDue)}
          />
        </FormGroup>
        <br />
        <Stack spacing={5}>
          <Button onClick={() => handleOpen()} variant="contained">
            View Invoice
          </Button>
        </Stack>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle>Invoice</DialogTitle>
          <DialogContent>
            <Box>
              {/* <iframe
                src={data.file}
                style={{
                  width: "1000px",
                  height: "75vh",
                }}
              /> */}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()}>Close</Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggle(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
